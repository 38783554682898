
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';
import Favicon from 'react-favicon';




import NavBar from './components/navBar'

import config from './config';


class Terms extends React.Component {



  render() {


    return (
      <section className="section-padding mt-5" id="terms_page"  >
        <div className="container">

          <div className="col-12">

           <div className="page-title text-center d-flex flex-column">
              <h5 className="title mb-5">Terms</h5>
              <h1 className="section-title">Terms & Conditions</h1>
              <h3>IMPORTANT LEGAL NOTICE</h3>
              <p className="text-left col-12">


                This page (together with our <a href="cookies.html">Cookies Policy</a>) sets out the terms and conditions
                ("Platform Terms") on which
                we ("we" , us, our or "{config.clientName}"), provide use of and access to our services, including without limitation
                access to any websites,
                mobile apps, products, applications,
                tools and features provided by our Supplier ORDRWare ("ORDRWare", "Roboworks"), who are a company whose registered office is
                Westminster House, 10 Westminster Road, Macclesfield, Cheshire, SK10 1BX and company number 10955579.

                <br /><br />
                "Platform" means the ORDRWare provided Mobile Application you use for placing orders with us and any associated websites or
                other applications provided by ORDRWare that are used to access our services and or orders goods from us.


               <br />

                Please read
                these Platform Terms carefully before ordering any services or products through, the Platform.
                {/* , as your
                purchase of any products or services offered on the Platform is subject to these Platform Terms. By ordering
                services of goods (e.g Food or Drink) from us you agree to be bound by these Platform Terms. Use
                of the website, is also subject to these Platform Terms. We reserve the right to change these
                Platform Terms from time to time by changing them on this page. We advise you to print a copy of these
                Platform Terms for future reference. These Platform Terms are only in the English language. Use of your
                personal information submitted via the Platform is governed by our Privacy Policy. For the avoidance of
                doubt, please note that references to "Platform" in these Platform Terms include any current or future
                version of our services and associated social media pages and any ORDRWare application and third party application
                through which you access our website or services, in each case
                whether accessed through any current or future platform or device (including without limitation any mobile
                website, mobile application, affiliate website or related website for accessing our website or services that
                may be developed from time to time). */}
               </p>
            </div>

            <div className="col-12">

              <h3>
                1. WEBSITE AND SERVICE ACCESS TERMS
              </h3>
              <p>
                1.1. Website and Service access: You may access some areas of the Website without making an Order or
                registering your details with us. Most areas of the Website are open to everyone.
              </p>
              <p>
                1.2. Acceptance of terms: By placing an order with us for any of our products or services, you indicate that
                you accept these Platform Terms. If you do not accept these Platform Terms, you should leave our Website and any of our Services
                immediately, and you will not be able to order any Services or Products through the Website or Platform, upon returning we will assume you
                intend to accept these terms.
               </p>
              <p>
                1.3. Revision of terms: We may revise these Platform Terms at any time. You should check the Platform
                regularly to review the current Platform Terms, because they are binding on you. You will be subject to the policies
                and terms and conditions in force at the time that you place an Order through us.
              </p>
              <p>
                1.4. Responsibility: You are responsible for making all arrangements necessary for you to have access to the
                Services. You are also responsible for ensuring that all persons who access the Services through your
                Internet connection are aware of these Platform Terms and that they comply with them.
              </p>

              <h3>2. YOUR STATUS</h3>
              <p>
                2.1. Capacity and age: By placing an Order for our Services with us, you warrant that: 2.1.1. You are legally
                capable of entering into binding contracts; and 2.1.2. You are at least 18 years old.
              </p>

              <h3>3. PRICING</h3>

              <p>
                3.1. VAT and delivery costs: Prices will be as quoted on the Website or within the App. These prices include VAT but may exclude delivery costs
               (if you opt for delivery instead of collection) and any administration or service charge imposed by the Restaurant.
               These will be added to the total amount due where applicable.
              </p>
              <p>
                3.2. Incorrect pricing: It is possible that our prices may be incorrect. We may determine at any time if our
                published or quoted prices are incorrect. In such an event we are not under any obligation to ensure that the
                Order is provided to you at the incorrect price or to compensate you in respect of incorrect pricing.
              </p>
              <p>
              3.3 Payment for Orders must be made by an accepted credit or debit card through the App by you. Payment is taken before we receive the order.
              </p>
              <p>
                3.4 f you pay by credit or debit card, you may be required to show the card to us at the time of delivery or collection as proof of identification
                and so that we can check that the card corresponds with the receipt data for the Order. Please note that from time to time there
                 may be delays with the processing of card payments and transactions; this may result in payments taking up to sixty (60) days to be deducted from your bank account or charged to your credit or debit card.
              </p>
              <p>
                3.5 Credit and discount vouchers: A credit or discount may apply to your Order if you use a promotional voucher or code recognised by the Platform,
                and you pay for any balance by credit or debit card. Please note that because of standard banking procedures, your bank or card issuer will initially
                "ring-fence" the full amount of the Order (before any credit or discount) in your account for between 3 to 5 working days (or longer, depending on your bank or card issuer),
                and this amount will therefore be unavailable in your account for that period.
                The credit or discount will be applied at the time your bank or card issuer transfers the funds for your Order to us,
                at which point the credit or discounted amount will not be transferred to us and will instead be released by your bank or card issuer back into your
                available balance. You acknowledge and agree that neither we nor ORDRWare will be responsible or liable to you in relation to this delay
                by your bank or card issuer in the release of funds back into your account.
              </p>
              <p>
              3.6 Rejected Orders: Because of standard banking procedures, once you have submitted an Order that you are paying for by credit or debit card and your payment has been authorised,
              your bank or card issuer will "ring-fence" the full amount of your Order. If your Order is subsequently rejected by us or cancelled for any other reason, your bank or card issuer will not transfer
              the funds for the Order to us, and will instead release the relevant amount back into your available balance. However, this may take between 3 to 5 working days (or longer, depending on your bank or card issuer).
              You acknowledge and agree that neither we nor ORDRWare will be responsible or liable to you in relation to this delay by your bank or card issuer in the release of funds back into your account.
              </p>
              <p>
               3.7 Refunds: If we provide you with a refund, due to our payment merchant withholding a transaction charge for each order placed you agree that in all cases this transaction charge and any other charges deducted from your
               order by our suppliers will not be included in the refund. ORDRWare will in no circumstances be liable to you for any element of a refund that is requested, or due to you.
                </p>





              <h3>4. LICENCE</h3>
              <p>
                4.1. Terms of permitted use: You are permitted to use the Platform and Website and print and download
                extracts
                from the Website for your own personal non-commercial use on the following basis: 4.1.1. You must not misuse
                the Website (including by hacking or "scraping"). 4.1.2. Unless otherwise stated, the copyright and other
                intellectual property rights in the Website and Platform and in material published on it (including without
                limitation photographs and graphical images) are owned by us, ORDRWare or our licensors. These works are protected by
                copyright laws and treaties around the world and all rights are reserved. For the purposes of these Platform
                Terms, any use of extracts from the Website other than in accordance with paragraph 4.1 is prohibited. 4.1.3.
                You must not modify the digital or paper copies of any materials that you print off in accordance with
                paragraph 4.1 and you must not use any pictures, photographs or any other graphics, video or audio sequences
                separately from any accompanying text. 4.1.4. You must ensure that our status as the author of the material
                on
                the Website is always acknowledged. 4.1.5. You are not allowed to use any of the materials on the Website or
                the Website itself for commercial purposes without obtaining a licence from us to do so.
               </p>
               <p>
                4.2. Limitation on use: Except as stated in paragraph 4.1, this Website nor any other part of the Platform may not be used, and no part of the Website may be
                reproduced or stored in any other website or included in any public or private electronic retrieval system or
                service, without our prior written permission.
                </p>
              <p>
                4.3. Reservation of rights: Any rights not expressly granted in
                these Platform Terms are reserved.
              </p>

              <h3>5. SERVICE ACCESS</h3>
              <p>
               5.1. Platform and Website availability: While we try to ensure our services including the Website and Apps available
                twenty four (24) hours a day, we do not undertake any obligation to do so, and we will not be liable to you
                if the Website or any of our Services is unavailable at any time or for any period.
               </p>
              <p>
               5.2. Suspension of access: Access to our Services may be suspended temporarily at any time and without notice.
              </p>
              <p>
               5.3. Information security: The
                transmission of information via the internet is not completely secure. We cannot guarantee the security of
                your data transmitted to the Website or our Services or the Platform or if any element of our Services transmits data to other Services and or to third parties (e.g Facebook); any
                transmission is at your own risk.
        </p>

              <h3>6. VISITOR MATERIAL AND REVIEWS</h3>
              <p>
               6.1. General: 6.1.1. Other than personally identifiable information, which is covered under our Privacy
                Policy,
                any material you post, upload or transmit or upload to our Service (including without limitation Reviews)
                ("Visitor Material") will be considered non-confidential and non-proprietary. By posting, uploading or
                transmitting any Visitor Material, you represent and warrant that you own or otherwise control all of the
                rights to such Visitor Material. You agree that we will have no obligations with respect to any Visitor
                Material, and that we and anyone we designate will be free to copy, disclose, distribute, incorporate and
                otherwise use any Visitor Material and all data, images, sounds, text and other things embodied in it for any
                and all commercial or non-commercial purposes. 6.1.2. You represent and warrant that that any Visitor
                Material
                you post, upload or transmit does not and will not breach any of the restrictions in paragraphs 6.2 to 6.3
                below.
        </p>
              <p>
               6.2. Visitor Material Policy: You are prohibited from posting, uploading or transmitting to or from the
                Website any Visitor Material (including any Reviews) that: 6.2.1. breaches any applicable local, national or
                international law; 6.2.2. is unlawful or fraudulent; 6.2.3. amounts to unauthorised advertising; or 6.2.4.
                contains viruses or any other harmful programs.
        </p>
              <p>
                6.3. Visitor Reviews Policy: In particular (but without
                limitation), any Reviews that you submit through the Website must not: 6.3.1. contain any defamatory, obscene
                or offensive material; 6.3.2. promote violence or discrimination; 6.3.3. infringe the intellectual property
                rights of another person; 6.3.4. breach any legal duty owed to a third party (such as a duty of confidence);
                6.3.5. promote illegal activity or invade another's privacy; 6.3.6. give the impression that they originate
                from us; or 6.3.7. be used to impersonate another person or to misrepresent your affiliation with another
                person.
        </p>
              <p>
                6.4. Removal of Reviews: The prohibited acts listed in paragraphs 6.2 and 6.3 above are non-exhaustive.
                We reserve the right (but do not undertake, except as required by law, any obligation) and have the sole
                discretion to remove or edit at any time any Reviews or other Visitor Material posted, uploaded or
                transmitted
                to the Website or Platform that we determine breaches a prohibition in paragraphs 6.2 or 6.3 above, is
                otherwise objectionable or may expose us or any third parties to any harm or liability of any type, or for
                any
                other reason.
        </p>
              <p>
                6.5. Use of Reviews: The Reviews and other Visitor Material contained on the Website or Platform
                are for information purposes only and do not constitute advice from us. Reviews and Visitor Material reflect
                the opinions of customers who have ordered through the Website or other third parties, and any statements,
                advice or opinions provided by such persons are theirs only. Accordingly, to the fullest extent permitted by
                law, we assume no responsibility or liability to any person for any Reviews or other Visitor Material,
                including without limitation any mistakes, defamation, obscenity, omissions or falsehoods that you may
                encounter in any such materials.
        </p>
              <p>
                6.6. Liability: You agree to indemnify us against any losses, damages and
                claims (and all related costs) incurred by or made against us by you or any other third party
                arising
                out of or in connection with any Reviews or other Visitor Material that you provide in breach of any of the
                representations and warranties, agreements or restrictions set forth in this paragraph 6.
        </p>
              <p>
                6.7. Disclosure to
                authorities and courts: You acknowledge that we will fully co-operate with any competent authority requesting
                or directing us to disclose the identity or location of anyone posting any Reviews or other Visitor Material
                in
                breach of paragraph 6.2 or 6.3 or any other applicable restriction and you release us to the fullest extent
                permitted by law from all liability in relation to such disclosure.
        </p>

              <h3>7. LINKS TO AND FROM OTHER WEBSITES</h3>
              <p>
                7.1. Third party websites: Links to third party websites on the Website are provided solely for your
                convenience. If you use these links, you leave the Website. We have not reviewed and do not control any of
                these third party websites (and are not responsible for these websites or their content or availability). We
                do
                not endorse or make any representation about these websites, their content, or the results from using such
                websites or content. If you decide to access any of the third party websites linked to the Website, you do so
                entirely at your own risk.
        </p>
              <p>
                7.2. Linking permission: You may link to the Website's homepage (www.ORDRWare.com),
                provided that: 7.2.1. you do so in a fair and legal way which does not damage or take advantage of our
                reputation; 7.2.2. you do not establish a link from a website that is not owned by you or in a way that
                suggests a form of association with or endorsement by us where none exists; 7.2.3. any website from which
                you link must comply with the content standards set out in these Website Terms (in particular paragraph 9
                (Visitor
                Materials and Reviews)); 7.2.4. we have the right to withdraw linking permission at any time and for any
                reason.
        </p>

              <h3>8. DISCLAIMERS</h3>
              <p>
                8.1. Website and or platform information: While we try to ensure that information on the Website and
                Platform is correct, we do not promise it is accurate or complete. We may make changes to the material on the Platform
                or Website, or to the Service, Products and prices described on it, at any time without notice. The material
                on the Website may be out of date, and we make no commitment to update that material.
        </p>
              <p>
                8.2. Allergy, dietary and other menu information: If you have, or someone you are ordering for has, a concern about food allergies,
                intolerances or other dietary preferences, you should always contact us directly before placing an order.
        </p>
              <p>
               8.3. Our actions and omissions: The
                legal contract for the supply and purchase of Products ordered with us, is between you and us and does not include ORDRWare as they have no control over
                the actions or omissions of us our you. Without limiting the
                generality of the foregoing, you acknowledge and accept the following by using the Website and Platform:
               8.3.1. ORDRWare do not give any undertaking that the Products ordered through the Platform by you
                will be of satisfactory quality or suitable for your purpose and we disclaim any such warranties.
        </p>
              <p>
               8.4. Exclusion of terms: We provide you with access to the Website, Platform and Service on the basis that, to the maximum extent permitted by
                law, we and ORDRWare exclude all representations, warranties, conditions, undertakings and other terms in relation to the
                Website, Platform and Service (including any representations, warranties, conditions, undertakings and other
                terms which might otherwise apply to the Website or Service, or be otherwise implied or incorporated into
                these Platform Terms, by statute, common law or otherwise ).
        </p>

              <h3>9. LIABILITY</h3>
              <p>
                9.1. General: Nothing in these Platform Terms excludes or limits our liability for death or personal injury
                arising from our fraudulent misrepresentation. Nothing in these Website Terms affects your statutory
                rights.
        </p>
              <p>
                9.2. Exclusion of liability: Subject to clause 9.1, we nor ORDRWare will under no circumstances whatever be liable
                to you, whether in contract, tort (including negligence), breach of statutory duty, participation in our
                on-boarding or sign-up process, or otherwise, even if forseeable, arising under or in connection with the Service or the Website or the Platform (including the
                use, inability to use or the results of use of the Service or the Website Platform) for: 9.2.1. any loss of
                profits, sales, business, or revenue; 9.2.2. loss or corruption of data, information or software; 9.2.3.
                loss
                of anticipated savings; 9.2.4. loss of business opportunity; 9.2.5. loss of goodwill; 9.2.6. loss of time
                or 9.2.7. any
                indirect
                or consequential loss.
        </p>


              <p>
                9.3. Limitation of liability: Subject to clauses 7, 8.1 and 9.2, our total liability
                to you in respect of all other losses arising under or in connection with the Service or the Website, whether
                in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in no circumstances
                exceed £10. ORDRWare's liability to you in respect of all other losses arising under or in connection with the Service or the Website, whether
                in contract, tort (including negligence), breach of statutory duty, or otherwise, shall in all cases be excluded and will in no circumstances exceed £0.
        </p>
              <p>
                9.4. Additional costs: You assume full and
                sole responsibility for any additional or associated costs that you may incur in connection with or as a
                result of your use of the Website, our Platform or any of our services, including without limitation costs relating,
                the sign-up or on-boarding process, use of the platform or costs relating to the servicing, repair or
                adaptation of any equipment, software or data that you may own, lease, license or otherwise use.
        </p>

              <h3>10. TERMINATION</h3>
              <p>
                10.1. Grounds for termination: We may terminate or suspend (at our absolute discretion) your right to use the
                Website, Platform and the Service immediately if we believe
                in our sole discretion that: 10.1.1. you have used the Platform or Website in breach of paragraph 7.1 (License);
                10.1.2. you have posted Reviews or other Visitor Material in breach of paragraphs 9.2 or 9.3 (Visitor
                Material and Reviews); 10.1.3. you have breached paragraph 7.2 (Links to and from other websites); or 10.1.4. you
                have breached any other material terms of these Platform Terms ; or 13.1.5. you do not pay your fees as they fall due
        </p>
              <p>
                10.2. Obligations upon termination: Upon termination
                or suspension you must immediately destroy any downloaded or printed extracts from the Website or Platform.
        </p>

              <h3>12. WRITTEN COMMUNICATION</h3>
              <p>
                12.1. Applicable laws require that some of the information or communications we send to you should be in
                writing. When using the Website or Platform or ordering Products via the Website or Platform, you accept that
                communication with us will be mainly electronic. We will contact you by email or provide you with information
                by posting notices on the Website. For contractual purposes, you agree to this electronic means of
                communication and you acknowledge that all contracts, notices, information and other communications that we
                provide to you electronically comply with any legal requirement that such communications be in writing. This
                condition does not affect your statutory rights.
        </p>

              <h3>13. EVENTS OUTSIDE OUR CONTROL</h3>
              <p>
                13.1. We nor ORDRWare will not be liable or responsible for any failure to perform, or delay in performance of, any of our
                obligations under these Website Terms that is caused by events outside our reasonable control ("Force Majeure
                Event").
        </p>
              <p>
                13.2. A Force Majeure Event includes any act, event, non-occurrence, omission or accident beyond our
                reasonable control and includes in particular (without limitation) the following: 13.2.1. strikes, lock-outs
                or
                other industrial action; 13.2.2. civil commotion, riot, invasion, terrorist attack or threat of terrorist
                attack, war (whether declared or not) or threat or preparation for war; 13.2.3. fire, explosion, storm,
                flood,
                earthquake, subsidence, epidemic or other natural disaster; 13.2.4. impossibility of the use of railways,
                shipping, aircraft, motor transport or other means of public or private transport; 13.2.5. impossibility of
                the
                use of public or private telecommunications networks; and 13.2.6. the acts, decrees, legislation, regulations
                or restrictions of any government.
        </p>
              <p>
                13.3. Our performance under these Website Terms is deemed to be suspended
                for the period that any Force Majeure Event continues, and we will have an extension of time for performance
                for the duration of that period. We will use our reasonable endeavours to bring any Force Majeure Event to a
                close or to find a solution by which our obligations under these Website Terms may be performed despite the
                Force Majeure Event.
        </p>

              <h3>14. ADDITIONAL TERMS</h3>
              <p>
                14.1. Privacy Policy: We are committed to protecting your privacy and security. All personal data that we
                collect from you will be processed in accordance with our Privacy Policy. You should review our Privacy
                Policy.
        </p>
              <p>
                14.2. Other terms: You should also review our Cookies Policy for information regarding how and why we use
                cookies to improve the quality of the Website and Service, our Voucher Terms and Conditions for information
                regarding the use of credits and promotional discounts on the Website. All of these are incorporated into
                these Platform Terms by this reference.
        </p>
              <p>
                14.3. Severability: If any of these Platform Terms are determined by any
                competent authority to be invalid, unlawful or unenforceable to any extent, such term, condition or provision
                will to that extent be severed from the remaining terms, conditions and provisions which will continue to be
                valid to the fullest extent permitted by law.
        </p>
              <p>
                14.4. Entire agreement: These Platform Terms and any document
                expressly referred to in them constitute the whole agreement between you and us and supersede all previous
                discussions, correspondence, negotiations, previous arrangement, understanding or agreement between us
                relating
                to the subject matter of any contract.
        </p>
              <p>
                14.5. No waiver: Any failure or delay by you or us in enforcing (in
                whole or in part) any provision of these Platform Terms will not be interpreted as a waiver of your or our
                rights or remedies.
        </p>
              <p>
                14.6. Assignment: You may not transfer any of your rights or obligations under these
                Platform Terms without our prior written consent. We may transfer any of our rights or obligations under
                these
                Platform Terms without your prior written consent to any of our affiliates or any business that we enter into
                a
                joint venture with, purchase or are sold to.
        </p>
              <p>
                14.7. Headings: The headings in these Platform Terms are included
                for convenience only and shall not affect their interpretation.
        </p>

              <h3>15. COMPETITIONS</h3>
              <p>
                15.1 We may from time to time offer competitions. Our competition(s) may be made available on our website or
                social media
                platforms, such as Facebook.
        </p>

              <p>
                15.2 To enter our competition you must be over 18 years old.
        </p>

              <p>
                15.3 By entering any of our competitions, the entrant (you) agrees to these terms and conditions as well as
                our General Terms and Conditions
        </p>


              <p>
                15.3 By entering any of our competitions, the entrant (you) agrees to these terms and conditions
        </p>

              <p>
                15.4 The winner of the competition agrees to the use of his/her name and image in any publicity material, as
                well as their entry.
        </p>

              <p>
                15.5 We will judge the competition and decide on the winner of each competition. Some competitions require a
                minimum number of entrants until a winner is annouced. Where the minimim number of entrants is not completed,
                we may not annouce a winner.
        </p>

              <p>
                15.6 We reserve the right, at our sole discretion and at any time, to change or modify these terms and
                conditions, such change shall be effective immediately upon posting to this webpage.
        </p>

              <p>
                15.7 Our competitions are in no way sponsored, endorsed or administered by, or associated with, Facebook,
                Twitter or any other Social Network.
        </p>

              <p>
                15.8 Our decision is final and binding in all matters relating to any competition and no correspondence will
                be entered into.
        </p>

              <p>
                15.9 To enter our competition you must be over 18 years old
        </p>

              <p>
                15.10
                Competition Terms and any applicable Specific Competition Terms shall be governed by the laws of England and
                Wales and subject to the exclusive jurisdiction of the English courts.
        </p>
              <p>
                15.11 You may enter our competition by following the rules as described on the Competition Page. If there are
                no rules you may contact us at competitions@ordrware.com for further instruction
        </p>

              <p>
                15.12 For the avoidance of doubt, we are not responsible or liabile to you for any loss, costs however they
                may arise as a result of any of our compeitions or in the use of any of our services.
        </p>


              <p>
                15.2 We reserve right to cancel or amend the competition and these terms and conditions without notice
        </p>

              <h3>16. GOVERNING LAW AND JURISDICTION</h3>
              <p>
                16.1 These Website Terms shall be governed by and construed in accordance with English law. Disputes or
                claims
                arising in connection with these Platform Terms (including non-contractual disputes or claims) shall be
                subject
                to the exclusive jurisdiction of the English courts.
        </p>


            </div>
          </div>

        </div>
      </section>
    );
  }





}

export default Terms

