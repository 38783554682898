

import React, { Component } from "react"
import { Nav, Navbar, Link, Container } from 'react-bootstrap';
import config from "../config";

class NavBar extends Component {
  render() {


console.log('config',config)

    return (


      <Navbar
        id="site-navbar"
        className="nav-shadow"
        expand="md"
        style={{backgroundColor: config.colours.mainColour}}
        fixed="top"

      >
     <Container>
          <Navbar.Brand href="/"  style={{color: config.colours.fontColour}}>
            <img
              src={config.logo}
              width="30"
              height="30"
              className="d-inline-block align-top mr-3"
              alt={config.clientName}


            />{config.clientName}

          </Navbar.Brand>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />

          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ml-auto">
              <Nav.Link href="/#home_page" style={{color: config.colours.fontColour}}>Home</Nav.Link>
              <Nav.Link href="/#about_page" style={{color: config.colours.fontColour}}>About</Nav.Link>
              <Nav.Link href="/#map_page" style={{color: config.colours.fontColour}}>Location</Nav.Link>
              <Nav.Link href="/#contact_page" style={{color: config.colours.fontColour}}>Details</Nav.Link>
            </Nav>
          </Navbar.Collapse>
          </Container>
      </Navbar>





    );
  }


  getShops = (menu, loaded, handleSelectShop) => {

    if (!loaded) return null

    const shops = menu.shops
    //Create Array Sorted By Ordinal
    const shopsArr = Object.keys(shops).map(i => { return shops[i] }).sort((a, b) => a.ordinal - b.ordinal)

    console.log("ShopsArr", shopsArr)

    //console.log("Current Shop",this.state.currentShop)

    return (
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          Change Shop
              </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          {shopsArr.map((el, i) => { return <div class="dropdown-item" onClick={() => handleSelectShop(el.key)} > {el.name} </div> })}
        </div>
      </li>
    )
  }


}

export default NavBar