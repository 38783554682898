import React, { Component } from "react"
import config from "../config";

class Footer extends Component {


  render() {

    const twitter = !config.twitter ? null : <div className="m-3"><a href={config.twitter}><span className="icon-twitter"  style={{fontSize:"50px"}}></span></a></div>
    const instagram = !config.instagram ? null :  <div className="m-3"><a href={config.instagram}><span className="icon-facebook" style={{fontSize:"50px"}}></span></a></div>
    const facebook = !config.facebook? null : <div className="m-3"><a href={config.facebook}><span className="icon-instagram"style={{fontSize:"50px"}}></span></a></div>
    const { backLink } = config.colours;

    return (

<footer className="section-padding" style={{background:'#F2F2F2'}}>
<div className="container">
  <div className="row mb-5">
    <div className="col-md-12">
      <div className="row justify-content-center">

          <a href="/privacy" className="py-2 d-block">Privacy Policy</a>
          <p className="py-2 d-block pl-2 pr-2" > | </p>
          <a href="/terms" className="py-2 d-block">Terms</a>

      </div>
    </div>

  </div>

  <div className="row justify-content-center">
         {twitter}
         {instagram}
         {facebook}
  </div>


  <div className="row ">
     <div className="col-md-12 text-center">

    </div>
    <div className="col-md-12 text-center">
      {
        !backLink || backLink === "1" ?
        (
          <p> Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | Website Powered By <a href="https://www.ordrware.com" target="_blank">ORDRWare</a></p>
        )
      :
      <p>
       Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved | Website Powered By <a href="https://www.ordrware.com" target="_blank">ORDRWare</a> | Design By <a href="https://www.chromacreativestudio.co.uk" target="_blank">Chroma Creative Studios</a>
      </p>
      }
    </div>
  </div>
</div>
</footer>

);
}



}

export default Footer