
import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import $ from 'jquery';
import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import './css/style.css';
import './css/animate.css';
import './css/icomoon.css';
//import './css/normalize.css';
import './css/responsive.css';
import Favicon from 'react-favicon';




import NavBar from './components/navBar'

import config from './config';


class Privacy extends React.Component {



  render() {


    return (
      <section className="section-padding mt-5" id="terms_page"  >
        <div className="container">

          <div className="col-12">

           <div className="page-title text-center d-flex flex-column">
              <h5 className="title mb-5">Privacy</h5>
              <h1 className="section-title">Privacy Policy</h1>
              <h3>IMPORTANT LEGAL NOTICE</h3>
              <p className="text-left col-12">


                  We are committed to protecting the privacy of all visitors to the Website or Platform, including all visitors
                  who access the Website or Service through any mobile application or other platform or device. Please read the
                  following Privacy Policy which explains how we use and protect your information. You agree and where required
                  you consent to the collection, use and transfer of your information as set out in this policy.


               <br />
              </p>
            </div>
            <div class="col-12">

<h3>1. INFORMATION (THAT WE COLLECT FROM YOU)</h3>
<p>
  1.1. When you visit the Platform, Website or use the Service to make an Order from a
  Coffee shop through the Website or Platform, you may be asked to provide information about yourself including
  your name, contact details (such as telephone and mobile numbers and e-mail address) and payment information
  (such as credit or debit card information). We may also collect information about your usage of the Website,
  Platform and Service and information about you from the materials (such as messages and reviews) you post to
  the Website and the e-mails or letters you send to us.
</p>
<p>
  1.2. By accessing ORDRNow information and/or the
  Platform or Website or Service using mobile digital routes such as (but not limited to) mobile, tablet or
  other
  devices/technology including mobile applications, Bots, A.Is, then you should expect that our data collection
  and usage as set out in this Privacy Policy will apply in that context too. We may collect technical
  information from your mobile device or your use of the Website or the Service through a mobile device, for
  example, location data and certain characteristics of, and performance data about, your device,
  carrier/operating system including device and connection type, IP address, mobile payment methods,
  interaction
  with other retail technology such as use of NFC Tags, QR Codes or use of mobile vouchers. This information
  may
  be collected and used by us automatically if you use the Website or Service through your mobile device(s) via
  any ORDRNow mobile application, through your mobile's browser any other 3rd Party platform that connects to
  ORDRNow or otherwise.
</p>

<h3>2. USE OF YOUR INFORMATION</h3>
<p>
  2.1. Your information will enable us to provide you with
  access to the relevant parts of the Platform, Website and to supply the Service. It will also enable us to
  bill
  you and enable us and/or a Coffee Shop with whom you have placed an Order to contact you where necessary
  concerning the Service. For example, we and/or the Coffee Shop may use your information to provide you with
  status updates or other information regarding your Order by e-mail, telephone, mobile or mobile messaging
  (e.g.
  SMS, MMS etc.). We will also use and analyse the information we collect so that we can administer, support,
  improve and develop our business, for any other purpose whether statistical or analytical and to help us
  prevent fraud. Where appropriate, now and in the future you may have the ability to express your preferences
  around the use of your data as set out in this Privacy Policy and this may be exercised though your chosen
  method of using the Service, for example mobile, mobile applications or any representation of the Platform or
  Website.
</p>
<p>
  2.2. We may use your information to contact you for your views on the Service and to notify you
  occasionally about important changes or developments to the Website or the Service or the Platform.
</p>
<p>
  2.3. Where
  you have indicated accordingly, you agree that we may use your information to let you know about our other
  products and services that may be of interest to you including services that may be the subject of direct
  marketing and we may contact you to do so by post, telephone, mobile messaging (e.g. SMS, MMS etc.) as well
  as
  by e-mail.
</p>
<p>
  2.4. Where you have indicated accordingly, you agree that we may also share information with third
  parties (including those in the food, drink, leisure, marketing and advertising sectors) to use your
  information in order to let you know about goods and services which may be of interest to you (by post,
  telephone, mobile messaging (e.g. SMS, MMS etc.) and/or e-mail) and to help us analyse the information we
  collect so that we can administer, support, improve and develop our business and services to you.
</p>
<p>
  2.5. If you
  do not want us to use your data in this way or change your mind about being contacted in the future, please
  let
  us know by using the contact details set out in paragraph 7 below and/or amending your profile accordingly.
</p>
<p>
  2.6. Please note that by submitting Reviews regarding the Website, Service and/or Coffee Shops, you consent
  to
  us to use such Reviews on the Website and Platform and in any marketing or advertising materials. We will
  only
  identify you for this purpose by your first name and the city in which you reside (and any other information
  that you may from time to time consent to us disclosing).
</p>

<h3>3. DISCLOSURE OF YOUR INFORMATION</h3>
<p>
  3.1. The
  information you provide to us will be transferred to and stored on our servers which may be in or outside the
  European Economic Area, and may be accessed by or given to our staff working outside the United Kingdom and
  third parties including companies within the ORDRNow group of companies (which means our subsidiaries and
  affiliates, our ultimate holding company and its subsidiaries and affiliates) who act for us for the purposes
  set out in this policy or for other purposes notified to you from time to time in this policy. Countries
  outside the European Economic Area do not always have strong data protection laws. However, we will always
  take
  steps to ensure that your information is treated in accordance with this policy.
</p>
<p>
  3.2. The third parties with
  whom we share your information may undertake various activities such as processing credit card payments and
  providing support services for us. In addition, we may need to provide your information to any Coffee Shop
  that
  you have placed an Order with so as to allow the Coffee Shop to process and deliver your Order. By submitting
  your personal data, you agree to this transfer, storing or processing. We will take all steps reasonably
  necessary to ensure that your data is treated securely and in accordance with this Privacy Policy.
</p>
<p>
  3.3. If you
  have consented we may allow carefully selected third parties, including marketing and advertising companies,
  our affiliates and associates, to contact you occasionally about services that may be of interest to you.
  They
  may contact you by post, telephone, mobile messaging (e.g. SMS, MMS, etc.) as well as by e-mail. If you
  change
  your mind about being contacted by these companies in the future, please let us know by using the contact
  details set out in paragraph 7 below and/or by amending your profile accordingly.
</p>
<p>
  3.4. If our business enters
  into a joint venture with, purchases or is sold to or merged with another business entity, your information
  may
  be disclosed or transferred to the target company, our new business partners or owners or their advisors.
</p>
<p>
  3.5.
  We may use the information that you provide to us if we are under a duty to disclose or share your
  information
  in order to comply with (and/or where we believe we are under a duty to comply with) any legal obligation; or
  in order to enforce the Platform Terms and any other agreement; or to protect our rights or the rights of
  Coffee Shops or other third parties. This includes exchanging information with other companies and other
  organisations for the purposes of fraud protection and prevention.
</p>

<h3>4. SECURITY AND DATA RETENTION</h3>
<p>
  4.1. We take
  steps to protect your information from unauthorised access and against unlawful processing, accidental loss,
  destruction and damage. We will keep your information for a reasonable period or as long as the law requires.
</p>
<p>
  4.2. Where you have chosen a password which allows you to access certain parts of the Website, you are
  responsible for keeping this password confidential. We advise you not to share your password with anyone.
  Unless we negligently disclose your password to a third party, we will not be liable for any unauthorised
  transactions entered into using your name and password.
</p>
<p>
  4.3. The transmission of information via the internet
  is not completely secure. Although we will take steps to protect your information, we cannot guarantee the
  security of your data transmitted to the Website; any transmission is at your own risk. Once we have received
  your information, we will use strict procedures and security features to try to prevent unauthorised access.
</p>

<h3>5. ACCESSING AND UPDATING</h3>
<p>
  5.1. You have the right to see the information we hold about you ("Access Request") and
  to ask us to make any changes to ensure that it is accurate and up to date. If you wish to do this, please
  contact us using the contact details set out in paragraph 7 below. In the event that you make an Access
  Request, we reserve the right to charge a fee of ten pounds (£15.00) to meet our costs in providing you with
  details of the information we hold about you.
</p>

<h3>6. CHANGES TO OUR PRIVACY POLICY</h3>
<p>
  6.1. Any changes to our Privacy
  Policy will be posted to the Website and, where appropriate, through e-mail notification.
</p>

<h3>7. CONTACT</h3>
<p>
  7.1. All
  comments, queries and requests relating to our use of your information are welcomed and should be addressed
  to
  hello@ordrware.com
</p>

</div>

          </div>

        </div>
      </section>
    );
  }





}

export default Privacy

