// Consume the data generated from config
const config = require("./config.json");




export default {


  /*-----------------
  1. App Content
  ------------------*/
  client: config.client,
  clientName: config.name,
  logo: config.logo,
  hero: config.hero,
  background: config.background,
  favicon: config.favicon,

  /*-----------------
  2. Header Content
  ------------------*/
  aboutTitle: "ABOUT",
  aboutSubTitle: config.aboutSubTitle,
  aboutText:  config.aboutText,

  /*-----------------
  3. About Content
  ------------------*/
  headerTitle: config.headerTitle,
  headerSubTitle: config.headerSubTitle,
  headerText: config.headerText,


  /*-----------------
  4. NabBar Settings
  ------------------*/
  navOnTop: "true",
  homeURL: "http://www.google.com",


  /*-----------------
  5. Design
  ------------------*/
  mainColour: config.mainColour,
  secondColour: config.secondColour,
  fontColour: config.fontColour,
  opacity: config.opacity,
  overlay: config.overlay,
  colours: JSON.parse(config.colours),

  /*-----------------
  6. URLS
  ------------------*/
  apple:config.apple,
  google: config.google,
  facebook: config.facebook,
  instagram: config.instagram,
  twitter: config.twitter,


  /*-----------------
 7. Shops
  ------------------*/
  shops:config.shops,

  /*-----------------
  8. API & KEYS
  ------------------*/
  googleKey: config.googleKey

}